import * as React from "react"
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md"
export function NumericInput({
  onIncrement,
  onDecrement,
  className,
  disabled,
  ...props
}) {
  return (
    <div className="numeric-input">
      <input disabled={disabled} type="numeric" {...props} />
      <div className="arrows">
        <button
          disabled={disabled}
          className="increment"
          aria-label="Increment"
          onClick={onIncrement}
        >
          <MdArrowDropUp />
        </button>
        <button
          disabled={disabled}
          className="decrement"
          aria-label="Decrement"
          onClick={onDecrement}
        >
          <MdArrowDropDown />
        </button>
      </div>
    </div>
  )
}
