import * as React from "react"
import { Link } from "gatsby"
import { Main } from "../layouts/main"
import { StoreContext } from "../context/store-context"
import { LineItem } from "../components/line-item"
import { formatPrice } from "../utils/format-price"

// Importing styles
import "./styles/cart.scss"

// Importing Bootstrap components
import { Container, Row } from "react-bootstrap"

export default function CartPage() {
  const { checkout, loading } = React.useContext(StoreContext)
  const emptyCart = checkout.lineItems.length === 0

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  return (
    <Main>
      <div className="cart">
        <Container>
          {emptyCart ? (
            <div className="empty-cart">
              <h1 className="shout">Your cart is empty</h1>
              <p className="talk">
                Looks like you haven’t found anything yet. We understand that
                sometimes it’s hard to chose — maybe this helps:
              </p>
              <Link className="btn btn-primary" to="/search?s=BEST_SELLING">
                View trending products
              </Link>
            </div>
          ) : (
            <>
              <h1 className="shout">Your cart</h1>
              <table className="table">
                <thead className="thead">
                  <tr>
                    <th>Image</th>
                    <th>Product</th>
                    <th>Price</th>
                    <th>Qty.</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody className="tbody">
                  {checkout.lineItems.map((item) => (
                    <LineItem item={item} key={item.id} />
                  ))}
                  <tr className="summary">
                    <td></td>
                    <td className="hideable-td"></td>
                    <td className="hideable-td"></td>
                    <td>Subtotal</td>
                    <td>
                      {formatPrice(
                        checkout.subtotalPriceV2.currencyCode,
                        checkout.subtotalPriceV2.amount
                      )}
                    </td>
                  </tr>
                  <tr className="summary">
                    <td></td>
                    <td className="hideable-td"></td>
                    <td className="hideable-td"></td>
                    <td>Taxes</td>
                    <td>
                      {formatPrice(
                        checkout.totalTaxV2.currencyCode,
                        checkout.totalTaxV2.amount
                      )}
                    </td>
                  </tr>
                  <tr className="summary">
                    <td></td>
                    <td className="hideable-td"></td>
                    <td className="hideable-td"></td>
                    <td>Shipping</td>
                    <td>Calculated at checkout</td>
                  </tr>
                  <tr className="total">
                    <td></td>
                    <td className="hideable-td"></td>
                    <td className="hideable-td"></td>
                    <td>Total Price</td>
                    <td>
                      {formatPrice(
                        checkout.totalPriceV2.currencyCode,
                        checkout.totalPriceV2.amount
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="checkout-button">
                <button
                  onClick={handleCheckout}
                  disabled={loading}
                  className="btn btn-primary"
                >
                  Checkout
                </button>
              </div>
            </>
          )}
        </Container>
      </div>
    </Main>
  )
}
